import { createApp } from 'vue'
import App from './App.vue'
// Import Bootstrap CSS files
import Home from './components/Home.vue'
import Games from './components/Games.vue'
import Wallet from './components/Wallet.vue'
import Recharge from './components/Recharge.vue'
import Withdrawal from './components/Withdrawal.vue'
import UsdtWithdraw from './components/UsdtWithdraw.vue'
import TrxWin from './components/TrxWin.vue'
import TrxWin3 from './components/TrxWin3.vue'
import TrxWin5 from './components/TrxWin5.vue'
import TrxWin10 from './components/TrxWin10.vue'
import Wingo from './components/Wingo.vue'
import TimePoint from './components/TimePoint.vue'
import Wingo3 from './components/Wingo3.vue'
import Wingo5 from './components/Wingo5.vue'
import Wingo10 from './components/Wingo10.vue'
import TeamReport from './components/TeamReport.vue'
import RechargeHistory from './components/RechargeHistory.vue'
import WithdrawHistory from './components/WithdrawHistory.vue'
import UpiDeposit from './components/UpiDeposit.vue'
import Popup from './components/Popup.vue'
import UsdtDeposit from './components/UsdtDeposit.vue'
import GameStatistics from './components/GameStatistics.vue'
import GameHistory from './components/GameHistory.vue'
import Notification from './components/Notification.vue'
import AddBankAccount from './components/AddBankAccount.vue'
import BankCard from './components/BankCard.vue'
import MyTransaction from './components/MyTransaction.vue'
import NewSubordinate from './components/NewSubordinate.vue'
import Indexpage from './components/Indexpage.vue'
import Mine from './components/Mine.vue'
import Register from './components/Register.vue'
import Activity from './components/Activity.vue'
import DailySignIn from './components/DailySignIn.vue'
import DailyHistory from './components/DailyHistory.vue'
import GameRule from './components/GameRule.vue'
import GiftCode from './components/GiftCode.vue'
import Promotion from './components/Promotion.vue'
import Login from './components/Login.vue'
import Email from './components/Email.vue'
import CustomerService from './components/CustomerService.vue'
import {createRouter, createWebHistory} from 'vue-router'
require('./assets/css/index.css');
const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
   
  },
  {
    path: '/',
    name: 'Indexpage',
    component: Indexpage,
   
  },
  {
    path: '/Promotion',
    name: 'Promotion',
    component: Promotion,
   
  },
  {
    path: '/TeamReport',
    name: 'TeamReport',
    component: TeamReport,
   
  },
  {
    path: '/TimePoint',
    name: 'TimePoint',
    component: TimePoint,
   
  },
  {
    path: '/NewSubordinate',
    name: 'NewSubordinate',
    component: NewSubordinate,
   
  },
  {
    path: '/Mine',
    name: 'Mine',
    component: Mine,
   
  },
  {
    path: '/UpiDeposit',
    name: 'UpiDeposit',
    component: UpiDeposit,
   
  },
  {
    path: '/UsdtDeposit',
    name: 'UsdtDeposit',
    component: UsdtDeposit,
   
  },
  {
    path: '/Recharge',
    name: 'Recharge',
    component: Recharge,
   
  },
  {
    path: '/Popup',
    name: 'Popup',
    component: Popup,
   
  },
  {
    path: '/Withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
   
  },
  {
    path: '/UsdtWithdraw',
    name: 'UsdtWithdraw',
    component: UsdtWithdraw,
   
  },
  {
    path: '/WithdrawHistory',
    name: 'WithdrawHistory',
    component: WithdrawHistory,
   
  },
  {
    path: '/AddBankAccount',
    name: 'AddBankAccount',
    component: AddBankAccount,
   
  },
  {
    path: '/BankCard',
    name: 'BankCard',
    component: BankCard,
   
  },
  {
    path: '/GameHistory',
    name: 'GameHistory',
    component: GameHistory,
   
  },
  {
    path: '/Notification',
    name: 'Notification',
    component: Notification,
   
  },
  {
    path: '/RechargeHistory',
    name: 'RechargeHistory',
    component: RechargeHistory,
   
  },
  {
    path: '/GameStatistics',
    name: 'GameStatistics',
    component: GameStatistics,
   
  },
  {
    path: '/MyTransaction',
    name: 'MyTransaction',
    component: MyTransaction,
   
  },
  {
    path: '/TrxWin',
    name: 'TrxWin',
    component: TrxWin,
   
  },
  {
    path: '/TrxWin3',
    name: 'TrxWin3',
    component: TrxWin3,
   
  },
  {
    path: '/TrxWin5',
    name: 'TrxWin5',
    component: TrxWin5,
   
  },
  {
    path: '/TrxWin10',
    name: 'TrxWin10',
    component: TrxWin10,
   
  },
  {
    path: '/Wingo',
    name: 'Wingo',
    component: Wingo,
   
  },
  {
    path: '/Wingo3',
    name: 'Wingo3',
    component: Wingo3,
   
  },
  {
    path: '/Wingo5',
    name: 'Wingo5',
    component: Wingo5,
   
  },
  {
    path: '/Wingo10',
    name: 'Wingo10',
    component: Wingo10,
   
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
   
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register,
   
  },
  {
    path: '/Activity',
    name: 'Activity',
    component: Activity,
   
  },
  {
    path: '/DailySignIn',
    name: 'DailySignIn',
    component: DailySignIn,
   
  },
  {
    path: '/DailyHistory',
    name: 'DailyHistory',
    component: DailyHistory,
   
  },
  {
    path: '/GameRule',
    name: 'GameRule',
    component: GameRule,
   
  },
  {
    path: '/GiftCode',
    name: 'GiftCode',
    component: GiftCode,
   
  },
  {
    path: '/Email',
    name: 'Email',
    component: Email,
   
  },
  {
    path: '/CustomerService',
    name: 'Customer',
    component: CustomerService,
   
  },
  {
    path: '/Games',
    name: 'Games',
    component: Games,
   
  },
  {
    path: '/Wallet',
    name: 'Wallet',
    component: Wallet,
   
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
createApp(App).use(router).mount('#app')