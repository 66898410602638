<template>
   <div class="container teamReport">
        <div class="row header">
            <i @click="goBack" class="fa fa-angle-left back-button"></i>
            <p class="attendance">Gift</p>
        </div>
        <div data-v-695ca243="" class="redeem-container-header-belly">
            <img data-v-695ca243="" alt="" class="banner" data-origin="../assets/png/gift-d7507b9b.png" src="../assets/png/gift-d7507b9b.png">
        </div>

        <div data-v-695ca243="" class="redeem-container-content">
        <div data-v-695ca243="" class="redeem-container-receive">
            <p data-v-695ca243="">Hi</p>
            <p data-v-695ca243="">We have a gift for you</p>
            <h4 data-v-695ca243="">Please enter the gift code below</h4>
            <form>
                <input data-v-695ca243="" type="text" v-model="giftcode" complete="new-password" autocomplete="off" placeholder="Please enter gift code">
                <button data-v-695ca243="" @click.prevent="getGift()">Receive</button>
            </form>
            <div id="snackbar" class="van-toast van-toast--middle van-toast--text" style="z-index: 2009;display:none ">
                <div class="van-toast__text">success</div>
            </div>
        </div>
        <div data-v-695ca243="" class="redeem-container-record">
            <div data-v-695ca243="" class="redeem-container-record-title">
                <svg data-v-695ca243="" class="svg-icon icon-giftHistory">
                    <use xlink:href="#icon-giftHistory"></use>
                </svg>
                <!-- <img :src="getIcons('main', 'gRecord')" /> --><span data-v-695ca243="">History</span>
            </div>
            <div data-v-695ca243="" class="redeem-container-record-itemsBox">
                <div data-v-61888f52="" data-v-695ca243="" class="infiniteScroll" id="refreshdc90885e23c745a18f655812af1dbeda">
                    <div data-v-61888f52="" class="infiniteScroll__loading">
                    <!--v-if-->
                    <div data-v-f84b843f="" data-v-61888f52="" class="empty__container empty">
                        <center>
                            <svg data-v-f84b843f="" class="svg-icon icon-empty" style="opacity: 0.2;">
                                <use xlink:href="#icon-empty"></use>
                            </svg>
                            <p data-v-f84b843f="">No data</p>
                        </center>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
   </div>
</template>
 <script>
    import axios from 'axios';
     export default {
         name: 'GiftCodeView',
         data(){
             return{
                giftcode: null,
                currentTab: 'tab1',
                userdetails: {
                   username: null,
                   total: 0,
                },
                selfdepositbonus: [],
                lossbet: [],
                depositbonus: [],
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
        //   created: function() {
        //      this.getUserdetails();
        //   },
         methods:{
            pop(message) {
                document.getElementById("snackbar").innerHTML = message;
                document.getElementById("snackbar").style.display = "";
                setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1500);
            },
          goBack() {
             window.history.back();
          },
          getGift(){
            this.userdetails.username = localStorage.getItem('username');
            if(this.giftcode === null){
               this.pop("Enter gift code");
            }
            else{
                
               axios.get('https://manager.tcgame.life/trova/src/api/bet.php?action=gift&user=' + this.userdetails.username + '&giftcode=' + this.giftcode).then((res) => {
                        console.log("Success", res.data.message);
                        this.pop(res.data.message);
                        this.giftcode = null;
                        setTimeout(() => { this.$router.push("/GiftCode"); }, 600);
                }).catch((err) => {
                    console.log(err);
                })
                }
            },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>
 <style>
    
    .attendance{
        margin-left: 42% !important;
    }
    .redeem-container-header-belly{
        margin-left: -3.8%;
    }
    .banner{
        height:200% !important;
        width: 103.6% !important;
    }
    @media screen and (max-width:500) {
        .redeem-container-header-belly{
            margin-left: -12% !important;
        }
        .banner{
            height:200% !important;
            width: 104% !important;
        }
    }
</style>